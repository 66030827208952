import React from 'react';
import CartPaymentIcons from './CartPaymentIcons';

type Props = {
  freeShipping: boolean;
  country: string;
  staticUrl?: string;
};

const CartFooter = (props: Props) => {
  // Figure out the shipping benefit
  let shippingBenefit = (
    <li className="cart-benefit__note--shipping">Speedy shipping</li>
  );
  if (props.freeShipping) {
    shippingBenefit = (
      <li className="cart-benefit__note--shipping-free">
        <strong className="free-note">Free
        &amp; speedy shipping</strong>
      </li>
    );
  }

  // Render footer
  return (
    <div className="cart__footer">
      <ul className="cart-benefits">
        {shippingBenefit}
        <li className="cart-benefit__note cart-benefit__note--return">
          Extended 30-day return policy
        </li>
        <li className="cart-benefit__note cart-benefit__note--support">
          <a href="/membership">Membership benefits</a>
        </li>
      </ul>

      <CartPaymentIcons staticUrl={props.staticUrl} country={props.country}/>
    </div>
  );
};

export default CartFooter;
