import React from 'react';
import { connect } from 'react-redux';
import type { CartState } from '../cart_widget/state';
import CartFreeShippingDiff from '../cart/CartFreeShippingDiff';
import type { DiscountType, ShippingType } from '../cart_widget/types';

type Props = {
  couponCode: string;
  total_msrp: number;
  total_msrp_discount: number;
  total_discounts: number;
  estimated_shipping_price_base: number;
  estimated_total_base: number;
  currency: string;
  formatCurrency: (arg0: number, arg1: string) => string;
};

const mapStateToProps = (state: { cart: CartState }) => ({
  total_msrp: state.cart.data.total_msrp,
  total_msrp_discount: state.cart.data.total_msrp_discount,
  total_discounts: state.cart.data.total_discounts,
  estimated_shipping_price_base: state.cart.data.estimated_shipping_price_base,
  estimated_total_base: state.cart.data.estimated_total_base,
  currency: state.cart.data.currency,
});

const Discount = (props: DiscountType) => {
  if (props.totalMsrp === props.estimatedTotalBase) {
    return null;
  }

  return (
    <tr className="cart-total cart-total--discount">
      <th className="cart-total__label">Discount </th>
      <td className="cart-total__amount">
        <span>{props.discount}</span>
        <span className="sr-only" aria-live="polite">
          {`Amount discount off total order, ${props.discount}`}
        </span>
      </td>
    </tr>
  );
};

const Shipping = (props: ShippingType) => {
  if (props.estimatedShipping === null || props.estimatedShipping == undefined) {
    return <></>;
  }
  if (props.estimatedShipping === 0) {
    return (
      <tr className="cart-total cart-total--shipping">
          <th className="cart-total__label">Shipping</th>
          <td className="cart-total__free">
            <strong className="free-note">Free</strong>
          </td>
      </tr>
    )
    return <span className="free-note">Free</span>;
  }
  return (
        <tr className="cart-total cart-total--shipping">
          <th className="cart-total__label">Shipping</th>
          <td className="cart-total__amount">
            <>{props.shipping}</>
          </td>
      </tr>
  );
};

const CartTotals = (props: Props) => {
  const { currency } = props;

  const discount = props.formatCurrency(props.total_msrp_discount, currency);
  const subtotal = props.formatCurrency(props.total_msrp, currency);
  let shipping = "";
  if (props.estimated_shipping_price_base !== null && props.estimated_shipping_price_base !== undefined) {
      shipping = props.formatCurrency(
        props.estimated_shipping_price_base,
        currency,
      );
  };

  const total = props.formatCurrency(props.estimated_total_base, currency);

  return (
    <table className="cart-totals-table">
      <tbody>
        <tr className="cart-total cart-total--subtotal">
          <th className="cart-total__label">Subtotal</th>
          <td className="cart-total__amount">{subtotal}</td>
        </tr>
        <Discount
          totalMsrp={props.total_msrp}
          estimatedTotalBase={props.estimated_total_base}
          discount={discount}
        />
        <Shipping
          estimatedShipping={props.estimated_shipping_price_base}
          shipping={shipping}
        />
        <tr className="cart-total cart-total--grand" aria-live="polite">
          <th className="cart-total__label">Est. total</th>
          <td className="cart-total__amount">
            {currency} <span> {total}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default connect(mapStateToProps)(CartTotals);
